<template>
  <b-card>
    <aom-skeleton-loader v-if="isFetching" />
    <validation-observer
      v-else
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(submit)"
      >
        <b-row>
          <!-- Entity -->
          <b-col md="6">
            <h3
              v-if="groupName"
              class="mb-3"
            >
              {{ groupName }}
            </h3>
          </b-col>
        </b-row>
        <advanced-search
          :initial-rules="rules"
          is-filter-group
          :group-name="groupName"
          @update="updateAddedRules"
          @update:groupName="updateGroupName"
          @update:type="updateType"
        />
        <b-row>
          <b-col sm="6">
            <preview-count
              :is-fetching="fetchingPreview"
              :user-count-expr="userCountExpr"
            />
          </b-col>
          <b-col sm="6">
            <b-row class="d-flex align-items-end justify-content-end">
              <b-col
                sm="5"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="5"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  :disabled=" invalid || isUpdating"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>
                    <feather-icon
                      icon="SaveIcon"
                      size="16"
                      class="mr-50"
                    />
                    Save filtered group</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
      
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { entitysDisplay, SearchExprClass, filters, dateOperators } from '@models';
import { mapGetters } from 'vuex';
import { groupsService, usersService } from "@/services";
import AdvancedSearch from "./AdvancedSearch.vue";  
import AomSkeletonLoader from "@/@aom-core/AomSkeletonLoader.vue";
import PreviewCount from './partials/PreviewCount.vue';
import { ValidationObserver } from "vee-validate";
import _debounce from "lodash/debounce";

export default {
  name: 'EditFilterGroup',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    AdvancedSearch,
    AomSkeletonLoader,
    PreviewCount,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      rules: new SearchExprClass({
        type: filters.FILTER_SET_ALL
      }),
      isUpdating: false,
      groupName: '',
      isFetching: false,
      fetchingPreview: false,
      userCountExpr: 0
    };
  },
  computed:{
    ...mapGetters('programs',['programById', 'defaultProgramId']),
  },
  watch: {
    rules: {
      handler(n) {
        if(n.filters.length > 0) {
          this.$nextTick(() => {
            this.validateAndFetchPreview();
          });
        }
      },
      deep: true
    },
  },
  created() {
    this.loadFilterGroup();
  },
  methods: {
    clearForm() {
      this.$router.push({ name: 'champion-participants-groups', id: this.defaultProgramId });
    },
    fetchExprResults: _debounce(async function() {
      try {
        const filterModel = this.adjustBetweenDates();
        const updateModel = {...this.rules, filters: filterModel};
        this.fetchingPreview = true;
        const response  = await usersService.previewUserSearch(this.defaultProgramId, {search_expr: new SearchExprClass({...updateModel})});
        const {users_count} = response.data;
        // eslint-disable-next-line camelcase
        this.userCountExpr = users_count;
      } catch (e) {
        this.$toast(makeErrorToast("Error fetching preview search results."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.fetchingPreview = false;
      }
    }, 1000),
    async loadFilterGroup() {
      try {
        this.isFetching = true;
        const groupId = this.$route.params.groupId;
        const response  = await groupsService.getGroupById(this.defaultProgramId, groupId);   
        const {search_expr, name } = response.data;
        this.groupName = name;  
        const parsedRules = JSON.parse(search_expr);
        this.rules = {
          ...this.rules,
          ...parsedRules
        };     
      } catch (e) {
        this.$toast(makeErrorToast("Error fetching search results."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isFetching = false;
      }
    },
    async submit() {
      try {
        this.isUpdating = true;
        const searchModel  = await usersService.createUserSearch(this.defaultProgramId, {search_expr: new SearchExprClass({...this.rules})});
        const {users_count, id} = searchModel.data;
        // eslint-disable-next-line camelcase
        this.userCountExpr = users_count;
        this.updateFilterGroup(id);       
      } catch (e) {
        this.$toast(makeErrorToast("Error saving search results."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    async updateFilterGroup(searchId) {
      try {
        this.isUpdating = true;
        const groupId =this.$route.params.groupId;
        const response  = await groupsService.updateFilterGroup(this.defaultProgramId, groupId, {name: this.groupName, user_search: {id: searchId} } );
        const { id } = response.data;
        this.$toast(makeSuccessToast("Filter Group Updated."));
        this.$router.push({ name: 'champion-participants-filter-groups',params: { id: this.defaultProgramId, groupId: id }});
      } catch (e) {
        this.$toast(makeErrorToast("Error saving filter group."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    updateAddedRules(data) {
      this.rules = {
        ...data
      };
    },
    updateGroupName(name) {
      this.groupName = name;
    },
    updateType(type) {
      this.rules.type = type;
    },
    async validateAndFetchPreview() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      this.fetchExprResults();
    },
    adjustBetweenDates() {
      const newArray =  this.rules.filters.map(filter => {
        if(filter.expressions && Array.isArray(filter.expressions)) {
          return [{...filter,expressions: filter.expressions.map(exp => {
            if(String(exp?.op) === String(dateOperators.OP_BETWEEN)) {
              if(!Array.isArray(exp.value)) {
                const dateRange = exp.value.split(" to ");
                if (dateRange.length > 1) {
                  const startDate = dateRange[0];
                  const endDate = dateRange[1];
                  return {...exp, value: [startDate, endDate]};
                }
              }
            }
            return exp;
          })}];
        }
        return filter;
      });
      return newArray.flat();
    }
  },
  setup(){
    return {
      getValidationState,
      entitysDisplay
    };
  }
};
</script>